import { createContext, useContext, useReducer } from "react";

type TAppState = {
    address: string;
    pid: string | null;
    stage:
        | 0 // disclaimer
        | 1 // search address
        | 2 // info form
        | 3; // post message
    name: string;
    email: string;
    phone: string;
    submit_error: boolean;
    voucherStatus: null | "have" | "dontHave" | "notAvailable";
    files: File[];
    verified: boolean;
};
const isDev = process.env.NODE_ENV === "development";
const defaultAppState: TAppState = {
    submit_error: false,
    address: isDev ? "510 Transverse Ave, Pittsburgh, PA 15210, USA" : "",
    pid: isDev ? "recrrzjAiumNcEWe2" : null,
    stage: isDev ? 2 : 0,
    name: isDev ? "Jim Bui" : "",
    email: isDev ? "jim@simplyhomes.dev" : "",
    phone: isDev ? "6896896889" : "",
    voucherStatus: isDev ? "have": null,
    files: [],
    verified: isDev ? true : false,
};
const AppContext = createContext({ appState: defaultAppState, appDispatch: (_: TAppAction) => {} });
type TAppAction = { overwrite: Partial<TAppState> } | { type: "incrementStage" };
const appReducer = (state: TAppState, action: TAppAction): TAppState => {
    if ("overwrite" in action) return { ...state, ...action.overwrite };
    return { ...state };
};

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [appState, appDispatch] = useReducer(appReducer, defaultAppState);
    return <AppContext.Provider value={{ appState, appDispatch }}>{children}</AppContext.Provider>;
};
export const useAppContext = () => useContext(AppContext);
