import { useAppContext } from "../../contexts/AppContext";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import styles from "./PostMessage.module.scss";
const PostMessage = () => {
    const { appState } = useAppContext();
    const { voucherStatus, submit_error } = appState;
    const data = {
        error: {
            icon: CF_CDN_URL("/assets/error.svg"),
            header: "Submission Failed!",
            message: "Please try again later.",
        },
        have: {
            icon: CF_CDN_URL("/assets/check-circle.svg"),
            header: "Submission Successful!",
            message:
                "Thank you for your application. Simply will verify the details with the Property Manager in charge. Once validated, you will receive your refund directly from them.",
        },
        dontHave: {
            icon: CF_CDN_URL("/assets/error.svg"),
            header: "Sorry! You're not eligible",
            message: "Participation in the rebate program requires a valid housing voucher.",
        },
        notAvailable: {
            icon: CF_CDN_URL("/assets/email.svg"),
            header: "Please check your email",
            message:
                "Thank you for applying! Please check your email for instructions on how to provide us with a copy of your voucher so that we can finalize your approval.",
        },
    };
    const { header, message, icon } = submit_error || !voucherStatus ? data["error"] : data[voucherStatus];
    return (
        <article className={`${styles.contentC}`}>
            {icon && <img src={icon} alt="" />}
            <header>{header}</header>
            <p>{message}</p>
        </article>
    );
};

export default PostMessage;
