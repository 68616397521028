import styles from "./StageWelcome.module.scss";
const StageWelcome = () => {
    return (
        <section className={`${styles.contentC}`}>
            <header>
                Welcome to
                <br />
                Simply Rebate Program
            </header>
        </section>
    );
};

export default StageWelcome;