import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import styles from "./Background.module.scss";
const Background = () => {
    return (
        <div className={`${styles.contentC}`}>
            <div className={`${styles.bgCircle}`}></div>
            <img src={CF_CDN_URL("/assets/background-house.png")} alt="" className={`${styles.bgHouses}`} />
        </div>
    );
};

export default Background;
