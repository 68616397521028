import { useQuery } from "@tanstack/react-query";
import { simpAxios } from "../config/simpAxios/config";
import { TSHrMainInput_Option, useSHrDebounce } from "@simplyhomes/react";

export const useQAddresses = ({ search }: useQAddresses_Params) => {
    const dbSearch = useSHrDebounce(search, 500);
    return useQuery({
        queryKey: ["addresses", dbSearch],
        enabled: !!dbSearch && dbSearch.length > 2,
        queryFn: () => simpAxios.get("/addresses", { params: { search: dbSearch } }).then<axiosResponse>((res) => res.data),
        refetchInterval: 2 * 60 * 1000,
    });
};

type axiosResponse = { addresses: TSHrMainInput_Option[] };
type useQAddresses_Params = { search: string };
