import { FileUploader } from "react-drag-drop-files";
import ReCAPTCHA from "react-google-recaptcha";
import { SHrMainButton, SHrMainInput, TSHrMainInput_Option } from "@simplyhomes/react";
import { useAppContext } from "../../contexts/AppContext";
import styles from "./StageForm.module.scss";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { simpAxios } from "../../config/simpAxios/config";
import { useState } from "react";
const StageForm = () => {
    return (
        <article className={`${styles.contentC} ${styles.contentC_desktop_stage1}`}>
            <Left />
            <Right />
        </article>
    );
};
const Left = () => {
    const { appState, appDispatch } = useAppContext();
    const { address } = appState;
    return (
        <div className={`${styles.left} `}>
            <header>
                <button type="button" onClick={() => appDispatch({ overwrite: { stage: 1 } })}>
                    <img src={CF_CDN_URL("/assets/arrow_back.svg")} alt="back" />
                </button>
                <span>Step 2: Provide details</span>
            </header>
            <div className={`${styles.unitDetails}`}>
                <img src={CF_CDN_URL("/assets/location-marker.svg")} alt="marker" />
                <span>{address}</span>
            </div>
        </div>
    );
};
const Right = () => {
    const { appState, appDispatch } = useAppContext();
    const { email, name, phone, voucherStatus, files, verified, pid } = appState;
    const [submitting, setSubmitting] = useState(false);
    const allowedExt = ["png", "jpg", "jpeg", "pdf", "heic"];
    const isValid =
        !!name && !!email && !!phone && ((voucherStatus === "have" && files.length > 0) || (!!voucherStatus && voucherStatus !== "have")) && verified;
    const voucherOptions: TSHrMainInput_Option[] = [
        // { label: "I have a picture of my voucher available", value: "have" },
        // { label: "I don’t have a voucher", value: "dontHave" },
        { label: "Yes", value: "have" },
        { label: "No", value: "dontHave" },
        // { label: "I have a voucher, but not with me currently", value: "notAvailable" },
    ];
    const handleAddFiles = (fl: FileList) => appDispatch({ overwrite: { files: [...Array.from(fl), ...files] } });
    const handleRemoveFiles = (i: number) => appDispatch({ overwrite: { files: files.filter((_, j) => j !== i) } });
    const handleSubmit = async () => {
        if (!isValid) return;
        if (!pid) return appDispatch({ overwrite: { stage: 0 } });
        setSubmitting(true);
        const moveToPostMsgStage = () => appDispatch({ overwrite: { stage: 3 } });
        if (voucherStatus === "dontHave") return moveToPostMsgStage();
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("pid", pid);
        formData.append("voucherStatus", voucherStatus);
        files.forEach((f) => formData.append("files", f));
        await simpAxios
            .post("/submit", formData)
            .then(moveToPostMsgStage)
            .catch(() => appDispatch({ overwrite: { submit_error: true } }));
        setSubmitting(false);
    };
    return (
        <div className={`${styles.right}`}>
            <div className={`${styles.infoForm}`}>
                <header>Let's fill in some information</header>
                <p className={`${styles.asterisk}`}>* Please use the same email and phone you used for your Rental Application with the Property Manager</p>
                <label className={`${styles.input}`}>
                    <p>Full Name</p>
                    <SHrMainInput
                        type="text"
                        inputProps={{ placeholder: "John Doe" }}
                        className={`${styles.shrInput}`}
                        value={name}
                        onChange={(name) => appDispatch({ overwrite: { name } })}
                    />
                </label>
                <label className={`${styles.input}`}>
                    <p>Phone</p>
                    <SHrMainInput
                        type="text"
                        format="tel"
                        inputProps={{ type: "tel", placeholder: "(XXX) XXX - XXXX" }}
                        className={`${styles.shrInput}`}
                        value={phone}
                        onChange={(phone) => appDispatch({ overwrite: { phone } })}
                    />
                </label>
                <label className={`${styles.input}`}>
                    <p>Email</p>
                    <SHrMainInput
                        type="text"
                        inputProps={{ type: "email", placeholder: "username@example.com" }}
                        className={`${styles.shrInput}`}
                        value={email}
                        onChange={(email) => appDispatch({ overwrite: { email } })}
                    />
                </label>
                <label className={`${styles.input}`}>
                    <p>Do you have a voucher?</p>
                    <SHrMainInput
                        type="dropdown"
                        options={voucherOptions}
                        value={voucherStatus || ""}
                        onChange={(v: any) => appDispatch({ overwrite: { voucherStatus: v } })}
                        className={`${styles.shrInput}`}
                        hideOptionValues
                    />
                </label>
                <div className={`${styles.input} ${styles.voucherC}`}>
                    {voucherStatus === "have" && (
                        <>
                            <p>Voucher Upload</p>
                            <FileUploader multiple maxSize={10} handleChange={handleAddFiles} types={allowedExt}>
                                <label className={`${styles.uploadC}`}>
                                    <img src={CF_CDN_URL("/assets/file_upload.svg")} alt="" />
                                    <div>{files.length > 0 ? `Selected ${files.length} file${files.length > 1 ? "s" : ""}` : "Choose files to Upload"}</div>
                                    <p>{allowedExt.map((str) => str.toUpperCase()).join("/")} - maximum file size 10MB</p>
                                </label>
                            </FileUploader>
                        </>
                    )}
                    <ul className={`${styles.files}`}>
                        {files.map((f, i) => (
                            <li key={i}>
                                <span>{f.name}</span>
                                <SHrMainButton type="close" onClick={() => handleRemoveFiles(i)} />
                            </li>
                        ))}
                    </ul>
                </div>
                {/* IF ReCaptcha not working for you in DEV, make sure DevTool Scaling is set to 100%, weird I know */}
                {!!voucherStatus && (
                    <div className={`${styles.controlls}`}>
                        <ReCAPTCHA
                            id={Date.now().toString()}
                            onChange={() => appDispatch({ overwrite: { verified: true } })}
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        />
                        <SHrMainButton
                            enable={!submitting && isValid}
                            onClick={handleSubmit}
                            title={submitting ? "Submitting" : "Submit"}
                            className={`${styles.summitBtn}`}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default StageForm;
