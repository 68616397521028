import { SHrMainInput, TSHrMainInput_Option } from "@simplyhomes/react";
import styles from "./StageSearchAddress.module.scss";
import { useQAddresses } from "../../querries/useQAddresses";
import { useAppContext } from "../../contexts/AppContext";
const StageSearchAddress = () => {
    const { appState, appDispatch } = useAppContext();
    const { address } = appState;

    const qAddresses = useQAddresses({ search: address });
    const { addresses = [] } = qAddresses.data || {};

    const handleOptionSelect = (o: TSHrMainInput_Option) => appDispatch({ overwrite: { address: o.label, pid: o.value, stage: 2 } });
    const handleUpdateAddress = (address: string) => appDispatch({ overwrite: { address, pid: null } });
    return (
        <section className={`${styles.contentC}`}>
            <header>Step 1: Which unit have you applied for?</header>
            <SHrMainInput
                type="text"
                value={address}
                rightIcon={"1"}
                loading={qAddresses.isFetching}
                options={addresses}
                onChange={handleUpdateAddress}
                onOptionClick={handleOptionSelect}
                hideOptionValues
                inputProps={{ placeholder: "Search the address" }}
                className={`${styles.addressSearch}`}
            />
        </section>
    );
};

export default StageSearchAddress;
