import { useAppContext } from "../../contexts/AppContext";
import Nav from "../Nav/Nav";
import Background from "../background/Background";
import StageForm from "../stageForm/StageForm";
import PostMessage from "../postMessage/PostMessage";
import StageSearchAddress from "../stageSearchAddress/StageSearchAddress";
import StageWelcome from "../stageWelcome/StageWelcome";
import styles from "./App.module.scss";
import { useMemo } from "react";
const App = () => {
    const { appState } = useAppContext();
    const { stage } = appState;

    const showBG = useMemo(() => [0, 1, 3].includes(stage), [stage]);
    return (
        <section className={`${styles.contentC}`}>
            <Nav />
            <div className={`${styles.bodyC}`}>
                {stage === 0 && <StageWelcome />}
                {stage === 1 && <StageSearchAddress />}
                {stage === 2 && <StageForm />}
                {stage === 3 && <PostMessage />}
            </div>
            {showBG && <Background />}
        </section>
    );
};

export default App;
