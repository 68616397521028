import { useState } from "react";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import styles from "./Nav.module.scss";
import { SHrMainButton, SHrPopup } from "@simplyhomes/react";
import { useAppContext } from "../../contexts/AppContext";
const Nav = () => {
    const { appState, appDispatch } = useAppContext();
    const { stage } = appState;
    const [show, setShow] = useState(stage === 0 ? true : false);
    const handleDiscBtn = () => {
        if (stage === 0) appDispatch({ overwrite: { stage: 1 } });
        setShow(false);
    };
    return (
        <div className={`${styles.contentC}`}>
            <img src={CF_CDN_URL("/assets/logo.svg")} alt="logo" className={`${styles.logo}`} />
            <div className={`${styles.separator}`}></div>
            <div className={`${styles.subTitle}`}>Simply Rebate Program</div>
            <div className={`${styles.policyC}`} onClick={() => setShow(true)}>
                <img src={CF_CDN_URL("/assets/policy.svg")} alt="Policy Icon" />
            </div>
            <SHrPopup show={show}>
                <article className={`${styles.disclaimerC}`}>
                    <header>
                        <img src={CF_CDN_URL("/assets/policy.svg")} alt="Policy Icon" />
                        <span>Important Message</span>
                    </header>
                    <p>
                        Participation in the rebate program is conditional on having a valid housing voucher and is designed to cover 50% of the application fee
                        once you submit and pay for the rental application through the designated property manager.
                        <br />
                        <br />
                        <strong>
                            Eligibility is based on the validity of the voucher at the time of submission. The offer cannot be combined with other promotions,
                            and the company reserves the right to modify, suspend, or terminate the program without notice, subject to availability of funds.
                        </strong>
                        <br />
                        <br />
                        Participation implies agreement to these terms and release of the company from liability related to the program. The program is void
                        where prohibited and governed by law.
                    </p>
                    <SHrMainButton title={"I agree and continue"} className={`${styles.btn}`} onClick={handleDiscBtn} />
                </article>
            </SHrPopup>
        </div>
    );
};

export default Nav;
