import React from "react";
import ReactDOM from "react-dom/client";
import "@simplyhomes/react/src/styles/color1.css";
import "@simplyhomes/react/src/styles/global1.css";
import "./global.scss";
import App from "./components/App/App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppContextProvider } from "./contexts/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, refetchOnMount: false, retry: false } },
});
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AppContextProvider>
                {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} language="en"> */}
                    <App />
                {/* </GoogleReCaptchaProvider> */}
            </AppContextProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
